// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "t_sz f_cD";
export var storyRowWrapper = "t_jm f_jm f_cK";
export var storyLeftWrapper = "t_sB f_cz f_cP";
export var storyWrapperFull = "t_sC f_dD";
export var storyWrapperFullLeft = "t_nP f_dD f_cz f_cP f_cD";
export var contentWrapper = "t_q f_jn";
export var storyLeftWrapperCenter = "t_sD f_jp f_cz f_cD f_cP f_cJ";
export var storyRightWrapperCenter = "t_sF f_jt";
export var storyHeader = "t_sG f_js f_bz f_ds";
export var storyHeaderCenter = "t_jr f_jr f_bz f_ds f_fw f_cy";
export var storyParagraphCenter = "t_jq f_jq f_cy f_fw";
export var storyBtnWrapper = "t_sH f_m f_fX f_bz f_cz f_cG";
export var storyBtnWrapperCenter = "t_jw f_jw f_fX f_bz f_cz f_cD";
export var imageWrapper = "t_pX f_f9 f_b2";
export var imageWrapperFull = "t_pY f_bz f_bL f_z f_b2";