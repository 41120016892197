// extracted by mini-css-extract-plugin
export var alignDiscLeft = "p_qN f_gj f_cG f_fv";
export var alignLeft = "p_pF f_gj f_cG f_fv";
export var alignDiscCenter = "p_qP f_gk f_cD f_fw";
export var alignCenter = "p_cP f_gk f_cD f_fw";
export var alignDiscRight = "p_qQ f_gl f_cH f_fx";
export var alignRight = "p_pG f_gl f_cH f_fx";
export var footerContainer = "p_qR f_fS f_cW";
export var footerContainerFull = "p_qS f_fQ f_cW";
export var footerHeader = "p_kY f_kY";
export var footerTextWrapper = "p_qT f_bz";
export var footerDisclaimerWrapper = "p_k3 f_k3 f_dk";
export var badgeWrapper = "p_qV f_bz f_cz f_cP f_cF f_cJ";
export var footerDisclaimerRight = "p_k4 f_k4 f_cz";
export var footerDisclaimerLeft = "p_k5 f_k5 f_cz";
export var verticalTop = "p_qW f_cz f_cJ f_cN f_cL";
export var firstWide = "p_qX";
export var disclaimer = "p_qY";
export var socialDisclaimer = "p_qZ";
export var left = "p_q0";
export var wide = "p_q1 f_dy";
export var right = "p_q2 f_cK";
export var line = "p_gd f_gf f_dv";
export var badgeDisclaimer = "p_q3 f_cC f_cP f_cJ";
export var badgeContainer = "p_q4 f_cz f_cH f_cP";
export var badge = "p_q5";
export var padding = "p_q6 f_d7";
export var end = "p_q7 f_cH";
export var linkWrapper = "p_q8 f_fB";
export var link = "p_s f_fB";
export var colWrapper = "p_q9 f_dx";
export var media = "p_rb f_cx f_fy";
export var itemRight = "p_rc";
export var itemLeft = "p_rd";
export var itemCenter = "p_rf";
export var exceptionWeight = "p_rg v_s8";