// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_rh f_hn f_ds";
export var heroHeaderCenter = "q_hp f_hp f_ds f_fw";
export var heroHeaderRight = "q_hq f_hq f_ds f_fx";
export var heroParagraphLeft = "q_rj f_hk f_dw";
export var heroParagraphCenter = "q_hl f_hl f_dw f_fw";
export var heroParagraphRight = "q_hm f_hm f_dw f_fx";
export var heroBtnWrapperLeft = "q_rk f_m f_fX f_bz f_cz f_cG";
export var heroBtnWrapperCenter = "q_rl f_fY f_fX f_bz f_cz f_cD";
export var heroBtnWrapperRight = "q_rm f_fZ f_fX f_bz f_cz f_cH";
export var overlayBtnWrapper = "q_rn f_hj f_b3 f_cb f_cc f_cd f_cn f_dc";
export var design4 = "q_rp f_hh f_b3 f_cb f_cc f_cn";
export var heroExceptionSmall = "q_rq v_rq";
export var heroExceptionNormal = "q_rr v_rr";
export var heroExceptionLarge = "q_rs v_rs";
export var Title1Small = "q_rt v_rt v_sJ v_sK";
export var Title1Normal = "q_rv v_rv v_sJ v_sL";
export var Title1Large = "q_rw v_rw v_sJ v_sM";
export var BodySmall = "q_rx v_rx v_sJ v_s1";
export var BodyNormal = "q_ry v_ry v_sJ v_s2";
export var BodyLarge = "q_rz v_rz v_sJ v_s3";