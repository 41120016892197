// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "m_px f_cD";
export var datasheetRowWrapper = "m_mc f_mc f_cK";
export var datasheetLeftWrapper = "m_py f_cz f_cP";
export var datasheetWrapperFull = "m_pz f_dD";
export var datasheetWrapperFullLeft = "m_nJ f_dD f_cz f_cP f_cD f_cT";
export var contentWrapper = "m_q f_md";
export var contentWrapperCenter = "m_pB f_mj";
export var datasheetLeftWrapperCenter = "m_pC f_mg f_cz f_cD f_cP f_cJ";
export var datasheetImageCenterWrapper = "m_9 f_9 f_bz f_dv";
export var datasheetRightWrapperCenter = "m_pD f_mf";
export var datasheetFooter = "m_mh f_mh";
export var alignLeft = "m_pF f_fv";
export var alignCenter = "m_cP f_fw";
export var alignRight = "m_pG f_fx";
export var datasheetInnerWrapperNewLeft = "m_pH f_f8 f_bS f_b2 f_cz f_cP f_fj";
export var datasheetInnerWrapperLeft = "m_pJ f_f7 f_bS f_b2 f_cz f_cP f_c5 f_fj f_d9";
export var datasheetInnerWrapperRight = "m_pK f_f8 f_bS f_b2 f_cz f_cP f_fj";
export var datasheetRowWrapperReverse = "m_pL f_cK";
export var datasheetSubtitle = "m_pM f_dx";
export var tableRow = "m_pN";
export var cell = "m_pP";
export var tableRowWrapper = "m_pQ f_bz f_fv";
export var tableHeadingCell = "m_pR";
export var tableHeading = "m_pS";
export var tableRowStriped = "m_pT";
export var tableRowHeading = "m_pV";
export var dataTable = "m_pW f_bz";
export var imageWrapper = "m_pX f_f9 f_b2";
export var imageWrapperFull = "m_pY f_bL f_bz f_z f_b2";
export var imageWrapperIcon = "m_pZ";
export var titleMargin = "m_p0 f_ds";
export var datasheetInnerInnerWrapper = "m_p1 f_bz";
export var hasLabels = "m_p2";
export var label = "m_p3";
export var SmallSmall = "m_p4 v_p4 v_sJ v_s4";
export var SmallNormal = "m_p5 v_p5 v_sJ v_s5";
export var SmallLarge = "m_p6 v_p6 v_sJ v_s2";