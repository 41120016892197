// extracted by mini-css-extract-plugin
export var articleWrapper = "n_p7";
export var articleText = "n_p8 f_fv f_ds";
export var header = "n_p9 f_b2 f_bz";
export var headerImageWrapper = "n_qb f_ch f_b3 f_cc f_cb f_b7 f_cd f_ck f_cR";
export var headerGradient = "n_qc f_ch f_b3 f_cc f_cb f_b7 f_cd";
export var headerGradientOverlay = "n_qd f_ch f_b3 f_cc f_cb f_b7 f_cd";
export var headerContentWrapper = "n_qf f_cz f_cP f_cD f_cJ f_cl f_b2";
export var contentWrapper = "n_q f_bz";
export var dateTag = "n_qg f_df f_dr f_fq f_bz";
export var icon1 = "n_qh f_dP";
export var icon2 = "n_qj f_dP f_dJ";
export var tagBtn = "n_qk f_c1 f_dD f_fp f_fB";
export var headerText = "n_ql f_bz f_df f_ds f_cW";
export var center = "n_qm f_fw";
export var videoIframeStyle = "n_qn f_f0 f_bz f_bL f_cy f_c1 f_bV";
export var articleImageWrapper = "n_qp f_ds f_b2";
export var articleImageWrapperIcon = "n_qq f_ds f_b2";
export var articleRow = "n_qr f_cD f_z";
export var quoteWrapper = "n_hG f_cC f_cP f_ds";
export var quoteBar = "n_qs f_bL";
export var quoteText = "n_qt";
export var authorBox = "n_qv f_bz";
export var authorRow = "n_qw f_cD f_cW f_dy";
export var separator = "n_qx f_bz";
export var line = "n_gd f_bz f_gd f_dY f_dy";
export var authorImage = "n_l7 f_c7 f_b2 f_bz f_dt";
export var authorText = "n_qy f_dh f_dt";
export var masonryImageWrapper = "n_qz";
export var bottomSeparator = "n_qB f_bz f_dy";
export var linksWrapper = "n_qC f_dh f_fv";
export var comments = "n_qD f_cD f_dy";
export var sharing = "n_qF f_cz f_cD f_cJ";
export var shareText = "n_qG f_bz f_fw";
export var icon = "n_qH";
export var text = "n_qJ";
export var SubtitleSmall = "n_qK";
export var SubtitleNormal = "n_qL";
export var SubtitleLarge = "n_qM";